import { ref } from 'vue'
import type {
  IEthPragueSponsorOrPartnerFields,
  IEthPragueContentFields,
  IEthPraguePersonFields,
  IEthPragueHackathonSponsorsFields
} from '~/@types/generated/contentful'
const people = ref<IEthPraguePersonFields[]>()
const content = ref<
  IEthPragueContentFields & {
    ethPragueHackathonSponsors: IEthPragueHackathonSponsorsFields
  }
>()
const sponsors = ref<IEthPragueSponsorOrPartnerFields[]>()
const isContentfulLoaded = ref(false)

export default function useContentful() {
  const fetchContentfulData = async () => {
    const query = `{
      ethPragueContentCollection(limit: 1)  {
        items {
          pitchDeck {
            url
          }
          twitterLink
          telegramLink
          applyToSpeakLabel
          applyToSpeakLink
          getTicketsLabel
          getTicketsLink
          applyToHackLabel
          applyToHackLink
          applyToVolunteerLabel
          applyToVolunteerLink
          getInTouchLabel
          getInTouchLink
          duckTapeLink
          paralelniPolisLink
          isSectionFaqVisible
          faqWhatToExpectOnSiteLabel
          hackathonSustainabilityUrbanism
          hackathonRegenerativeFinance
          hackathonCommunityActivismAccessibility
          hackathonEthereumFuture
          hackathonSocialImpact
          hackathonHackersFavorite
          hackathonAudiencesFavorite
          hackathonTextTesting {
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                }
              }
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                  width
                  height
                }
              }
            }
          }
          faqWhatToExpectOnSiteText {
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                }
              }
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                  width
                  height
                }
              }
            }
          }
          faqLocationLabel
          faqLocationText {
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                }
              }
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                  width
                  height
                }
              }
            }
          }
          faqSleepAndRestLabel
          faqSleepAndRestText {
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                }
              }
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                  width
                  height
                }
              }
            }
          }
          faqAboutPragueLabel
          faqAboutPragueText {
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                }
              }
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                  width
                  height
                }
              }
            }
          }
          hackathonLabel
          hackathonText {
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                }
              }
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                  width
                  height
                }
              }
            }
          }
          manifestoText {
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                }
              }
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                  width
                  height
                }
              }
            }
          }
        }
      }
      ethPraguePeopleSortedCollection(limit: 1) {
        items {
          ethPraguePeopleOrder2025Collection(limit: 500) {
            items {
              sys {
                id
              }
              ... on EthPraguePerson {
                sys {
                  id
                }
                name
                twitter
                company
                role
                profileImage {
                  sys {
                    publishedAt
                    id
                  }
                  fileName
                  url
                }
              }
            }
          }
        }
      }
      ethPragueSponsorOrPartnerCollection {
        items {
          name
          link
          logo {
            sys {
              publishedAt
              id
            }
            fileName
            url
          }
          tier
        }
      }
    }`

    const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${
      import.meta.env.VITE_CONTENTFUL_SPACE_ID
    }/environments/master`

    const fetchOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_CONTENTFUL_ACCESS_TOKEN}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query
      })
    }

    try {
      isContentfulLoaded.value = false
      const response = await fetch(fetchUrl, fetchOptions)
      const JSONResponse = await response.json()

      if (!JSONResponse?.data) console.error('No data received from Contentful')
      // if (
      //   !JSONResponse?.data?.ethPraguePeopleSortedCollection?.items[0]
      //     ?.ethPraguePeopleSortedCollection?.items
      // )
      //   console.error('No people data received from Contentful')
      if (!JSONResponse?.data?.ethPragueContentCollection?.items[0])
        console.error('No content data received from Contentful')

      // if (!JSONResponse?.data?.ethPragueSponsorOrPartnerCollection?.items)
      //   console.error('No sponsors data received from Contentful')

      // people.value =
      //   JSONResponse?.data?.ethPraguePeopleSortedCollection?.items[0]?.ethPraguePeopleSortedCollection?.items

      content.value = {
        ...JSONResponse?.data?.ethPragueContentCollection?.items[0],
        ethPragueHackathonSponsors:
          JSONResponse?.data?.ethPragueHackathonSponsorsCollection?.items
      }

      sponsors.value =
        JSONResponse?.data?.ethPragueSponsorOrPartnerCollection?.items
      isContentfulLoaded.value = true
    } catch (error: any) {
      isContentfulLoaded.value = false
      throw new Error('Error during receiving data from Contentful', error)
    }
  }

  return {
    people,
    content,
    isContentfulLoaded,
    sponsors,
    fetchContentfulData
  }
}
